import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Lead = makeShortcode("Lead");
const NavLink = makeShortcode("NavLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Lead mdxType="Lead">
  <strong>Food From 101</strong> is a slapdash record of remixed recipes,
  cooking techniques, and a few baking miracles.
    </Lead>
    <NavLink to="/about" mdxType="NavLink">Learn more</NavLink>
    <NavLink to="/recipes" mdxType="NavLink">Browse recipes</NavLink>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      